import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {request} from "../timeTracking";
import authStorage from "../auth_storage";

function SelectSubproject() {
    const navigate = useNavigate()
    const [subprojects, setSubprojects] = useState([])
    const [isLeading, setIsLeading] = useState(false)
    const [working, setWorking] = useState(false)
    const {sessionId, projectId} = useParams();
    useEffect(() => {
        async function fetchSubprojects(projectId) {
            try {
                setIsLeading(true)
                const res = await request(`/api/v1/projects/${projectId}/subprojects`, authStorage.get())
                const data = await res.json()
                setSubprojects(data.payload)
            } catch (e) {
                console.error(e)
            } finally {
                setIsLeading(false)
            }
        }

        fetchSubprojects(projectId)
    }, [projectId]);

    const select = async (subprojectId) => {
        try {
            setWorking(true)
            const res = await request(`/api/v1/time-tracking/sessions/${sessionId}/change-session-to-project-type`, authStorage.get(), 'PUT', {
                projectId: Number(projectId),
                subprojectId,
            })
            if (res.status !== 200) {
                alert('Something went wrong')
            }
            navigate(`/sessions`)
        } catch (e) {
            setWorking(false)
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-2 col-lg-1">
                    <div className="my-3">
                        <Link to={`/sessions/${sessionId}/select-project`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                 className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className={"col-10 col-lg-11 align-content-center"}>
                    <h1>Select a subproject:</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                </div>
            </div>
            <div className="row justify-content-between">
                <div className="col-12">

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">-</th>
                            </tr>
                            </thead>
                            <tbody>
                            {subprojects.map(subproject => (
                                <tr key={subproject.id}>
                                    <td>{subproject.name}</td>
                                    <td>
                                        <button className="btn btn-secondary btn-sm"
                                                disabled={working}
                                                onClick={() => select(subproject.id)}>Select
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            {isLeading && <tr key={"loading"}>
                                <td colSpan={5} className="text-center">
                                    Loading...
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectSubproject;
import {useNavigate} from "react-router-dom";

function SessionTable({sessions, next}) {
    const navigate = useNavigate()
    const selectProject = (sessionId) => {
        navigate(`/sessions/${sessionId}/select-project`)
    }
    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Start</th>
                    <th scope="col">End</th>
                    <th scope="col">Type</th>
                    <th scope="col">Project</th>
                    <th scope="col">-</th>
                </tr>
                </thead>
                <tbody>
                {sessions.map(session => (
                    <tr key={session.id}>
                        <td>{session.id}</td>
                        <td>{new Date(session.startTimestamp).toLocaleString()}</td>
                        <td>{new Date(session.endTimestamp).getTime() === 0 ? 'Ongoing' : new Date(session.endTimestamp).toLocaleString()}</td>
                        <td>{session.type}</td>
                        <td>{session.type === 'PROJECT' && session.Project.name}</td>
                        <td>
                            {(session.type === 'CALLING' || session.type === 'NEW_LEAD') && new Date(session.endTimestamp).getTime() !== 0 &&
                                <button className="btn btn-secondary btn-sm"
                                        onClick={() => selectProject(session.id)}>Select a project</button>}
                        </td>
                    </tr>
                ))}
                <tr key={"next"}>
                    <td colSpan={5}>
                        <button className="btn btn-text d-block my-2 my-lg-3 m-auto loadMore"
                                onClick={next}>Mehr
                            laden
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default SessionTable;